import axios from "axios";

const BASE_API = process.env.REACT_APP_API_URL;
const BOARD_Id = process.env.REACT_APP_BOARD_ID;
const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN;

axios.interceptors.request.use((config) => {
  if (!config) {
    config = {};
  }

  config.headers["Access-Token"] = ACCESS_TOKEN;

  return config;
});

axios.defaults.baseURL = BASE_API;

class GroupServices {
  /**
   * GET ALL GROUPS
   */
  async getGroups() {
    const response = await axios.get(`/boards/${BOARD_Id}/groups`);

    return response.data;
  }

  /**
   * CREATE A NEW GROUP
   */
  async createGroup(data) {
    const response = await axios.post(`/boards/${BOARD_Id}/groups`, data);

    return response.data;
  }

  /**
   * UPDATE A GROUP
   */
  async updateGroup(data) {
    const response = await axios.put(`/boards/${BOARD_Id}/groups`, data);

    return response.data;
  }

  /**
   * DELETE A GROUP
   */

  async deleteGroup(data) {
    const response = await axios.delete(`/boards/${BOARD_Id}/groups`, { data });

    return response.data;
  }
}

export default GroupServices;
