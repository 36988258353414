import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Form,
  FormLabel,
  FormGroup,
  FormControl,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";

const MainModal = (props) => {
  const {
    groupId,
    taskId,
    title,
    show,
    onConfirm,
    onClose,
    confirmName,
    cancelName,
  } = props;

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const groups = useSelector((state) => state.groupReducers.groups);
  const tasks = useSelector((state) => state.taskReducers.tasks);

  // Show original data in edit modal
  useEffect(() => {
    if (groupId) {
      const group = groups.find((item) => item.id === groupId);
      setName(group.name);
      setDescription(group.description);
    }

    if (taskId) {
      const task = tasks.find((item) => item.id === taskId);
      setName(task.name);
      setDescription(task.body);
    }
  }, [groupId, groups, show, taskId, tasks, show]);

  const handleConfirm = () => {
    setName("");
    setDescription("");
    onConfirm(name, description);
  };

  const handleClose = () => {
    setName("");
    setDescription("");
    onClose();
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <FormLabel>Name</FormLabel>
            <FormControl value={name} onChange={handleName} />
          </FormGroup>
          <FormGroup>
            <FormLabel>Description</FormLabel>
            <FormControl
              as="textarea"
              value={description}
              onChange={handleDescription}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={handleClose}>
          {cancelName}
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          {confirmName}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MainModal;
