import React from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { legacy_createStore as createStore} from 'redux'
import { Provider } from 'react-redux';
import rootReducer from './redux/reducers/index.js';
import "bootstrap/dist/css/bootstrap.min.css";

import Board from "pages/Board";
import "./App.css";
import "styles/app.scss";

const store = createStore(rootReducer);

const App = () => {
  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <div className="App">
          <Board />
        </div>
      </DndProvider>
    </Provider>
  );
};

export default App;
