import React from "react";

const Navbar = () => {
  return (
    <div className="layout-navbar bg-dark px-5 py-3">
      <p className="text-light mb-0 h3">Trello Web</p>
    </div>
  );
};

export default Navbar;
