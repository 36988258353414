import React from "react";
import { useDispatch } from "react-redux";
import TaskServices from "services/taskServices";
import { updateTrigger } from "redux/actions/action";
import MainModal from "component/modal/MainModal";

const CreateTaskModal = (props) => {
  const { groupId, openModal, onClose } = props;
  const dispatch = useDispatch();

  const handleCreate = (name, description) => {
    const taskServices = new TaskServices();

    dispatch(updateTrigger("createTask"));

    taskServices.createTask({
      name,
      body: description,
      groupDto: {
        id: groupId,
      },
    });

    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <MainModal
      title="Create A New Task"
      show={openModal}
      onConfirm={handleCreate}
      onClose={handleClose}
      confirmName="Create"
      cancelName="Close"
    />
  );
};

export default CreateTaskModal;
