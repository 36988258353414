const initialState = {
  groups: [],
};

const groupReducers = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_GROUPS":
      return {
        ...state,
        groups: action.payload,
      };
    default:
      return state;
  }
};

export default groupReducers;
