import { combineReducers } from "redux";
import groupReducers from "./groupReducers/groupReducers";
import taskReducers from "./taskReducers/taskReducers";
import triggerReducers from "./triggerReducers/triggerReducers";

export default combineReducers({
  groupReducers,
  taskReducers,
  triggerReducers
});
