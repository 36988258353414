/**
 * GROUP ACTIONS
 */
export const updateGroups = (payload) => {
  return {
    type: "UPDATE_GROUPS",
    payload,
  };
};

/**
 * TASK ACTIONS
 */
export const updateTasks = (payload) => {
  return {
    type: "UPDATE_TASKS",
    payload,
  };
};

/**
 * TRIGGER ACTIONS
 */
export const updateTrigger = (payload) => {
  return {
    type: "TRIGGER",
    payload,
  };
};
