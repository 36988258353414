import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import GroupServices from "services/groupServices";
import { updateGroups } from "redux/actions/action";
import MainModal from "component/modal/MainModal";

const EditGroupModal = (props) => {
  const { groupId, openModal, onClose } = props;
  const dispatch = useDispatch();

  const groups = useSelector((state) => state.groupReducers.groups);

  const groupData = useMemo(() => {
    return groups.find((item) => item.id === groupId);
  }, [groups, groupId]);

  const handleSave = (name, description) => {
    const groupServices = new GroupServices();

    const updatedGroups = groups.map((item) => {
      return item.id === groupData.id ? { ...item, name, description } : item;
    });

    dispatch(updateGroups(updatedGroups));
    onClose();

    const body = {
      id: groupData.id,
      name,
      description,
    };

    groupServices.updateGroup(body);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <MainModal
      groupId={groupId}
      title="Edit Group"
      show={openModal}
      onConfirm={handleSave}
      onClose={handleClose}
      confirmName="Save"
      cancelName="Cancel"
    />
  );
};

export default EditGroupModal;
