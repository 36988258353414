import React from "react";
import { useDispatch } from "react-redux";
import GroupServices from "services/groupServices";
import { updateTrigger } from "redux/actions/action";
import MainModal from "component/modal/MainModal";

const CreateGroupModal = (props) => {
  const { openModal, onClose } = props;
  const dispatch = useDispatch();

  const handleCreate = (name, description) => {
    const groupServices = new GroupServices();

    dispatch(updateTrigger("createGroup"));

    const body = {
      name,
      description,
    };

    groupServices.createGroup(body);

    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <MainModal
      title="Create A New Group"
      show={openModal}
      onConfirm={handleCreate}
      onClose={handleClose}
      confirmName="Create"
      cancelName="Close"
    />
  );
};

export default CreateGroupModal;
