import axios from "axios";

const BASE_API = process.env.REACT_APP_API_URL;
const BOARD_ID = process.env.REACT_APP_BOARD_ID;
const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN;

axios.interceptors.request.use((config) => {
  if (!config) {
    config = {};
  }

  config.headers["Access-Token"] = ACCESS_TOKEN;

  return config;
});

axios.defaults.baseURL = BASE_API;

class TaskServices {
  /**
   * GET ALL TASKS
   */
  async getTasks() {
    const response = await axios.get(`/boards/${BOARD_ID}/tasks`);

    return response.data;
  }

  /**
   * CREATE A NEW TASK
   */
  async createTask(data) {
    const response = await axios.post(`/boards/${BOARD_ID}/tasks`, data);

    return response.data;
  }

  /**
   * UPDATE A TASK
   */
  async updateTask(data) {
    const response = await axios.put(`/boards/${BOARD_ID}/tasks`, data);

    return response.data;
  }

  /**
   * DELETE A TASK
   */

  async deleteTask(data) {
    const response = await axios.delete(`/boards/${BOARD_ID}/tasks`, { data });

    return response.data;
  }
}

export default TaskServices;
