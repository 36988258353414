import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { useDrop } from "react-dnd";
import GroupServices from "services/groupServices";
import { updateGroups } from "redux/actions/action";
import CreateTaskModal from "pages/modals/CreateTaskModal";
import EditGroupModal from "pages/modals/EditGroupModal";

const GroupCard = (props) => {
  const { children, id } = props;
  const dispatch = useDispatch();

  const groups = useSelector((state) => state.groupReducers.groups);
  const groupData = groups.find((item) => item.id === id);

  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "tasks",
    drop: () => {
      return { id, name: groupData.name };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;

  const openCreateModal = () => {
    setCreateModal(true);
  };

  const openEditModal = () => {
    setEditModal(true);
  };

  const handleClose = () => {
    setCreateModal(false);
    setEditModal(false);
  };

  const handleRemove = async () => {
    const groupServices = new GroupServices();
    const updatedGroups = groups.filter((item) => item.id !== id);
    dispatch(updateGroups(updatedGroups));

    const group = {
      ...groupData,
      id,
    };

    groupServices.deleteGroup(group);
  };

  return (
    <div className="group-card card border-1 shadow-sm mx-2 rounded-3">
      <div className="group-header rounded-top bg-dark p-2 d-flex align-items-start justify-content-between">
        <div className="heading">
          <h5 className="mb-0 text-light">{groupData.name}</h5>
          <p className="desc text-light">{groupData.description}</p>
        </div>
        <div className="d-flex">
          <Button size="sm" variant="secondary" onClick={openCreateModal}>
            Create
          </Button>
          <Button
            size="sm"
            variant="secondary"
            className="mx-1"
            onClick={openEditModal}
          >
            Edit
          </Button>
          <Button
            type="button"
            variant="danger"
            size="sm"
            className="close"
            aria-label="Close"
            onClick={handleRemove}
          >
            <span aria-hidden="true">&times;</span>
          </Button>
        </div>
      </div>
      <div
        ref={drop}
        className={`${isActive ? "active" : ""} dropbox p-3 rounded-bottom`}
      >
        {children}
        <CreateTaskModal
          groupId={id}
          openModal={createModal}
          onClose={handleClose}
        />
        <EditGroupModal
          groupId={id}
          openModal={editModal}
          onClose={handleClose}
        />
      </div>
    </div>
  );
};

export default GroupCard;
