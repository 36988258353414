import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import GroupServices from "services/groupServices";
import TaskServices from "services/taskServices";
import { updateTrigger, updateTasks, updateGroups } from "redux/actions/action";
import MainLayout from "layout/MainLayout";
import GroupCard from "component/cards/GroupCard";
import TaskCard from "component/cards/TaskCard";
import CreateGroupModal from "pages/modals/CreateGroupModal";

const Board = () => {
  const dispatch = useDispatch();

  const tasks = useSelector((state) => state.taskReducers.tasks);
  const groups = useSelector((state) => state.groupReducers.groups);
  const trigger = useSelector((state) => state.triggerReducers.trigger);

  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    const groupServices = new GroupServices();
    const taskServices = new TaskServices();

    groupServices.getGroups().then((data) => {
      dispatch(updateGroups(data));
    });

    taskServices.getTasks().then((data) => {
      dispatch(updateTasks(data));
    });

    dispatch(updateTrigger(""));
  }, [dispatch, trigger]);

  const handleOpenCreateModal = () => {
    setShowCreateModal(true);
  };

  const handleCloseModal = () => {
    setShowCreateModal(false);
  };

  const tasksForGroup = (groupId) => {
    return tasks
      .filter((task) => task?.groupDto?.id === groupId)
      .map((task) => <TaskCard key={task.id} id={task.id} />);
  };

  return (
    <div className="page page-board">
      <MainLayout>
        <div className="buttons-group mx-2">
          <Button variant="success" onClick={handleOpenCreateModal}>
            New Group
          </Button>
        </div>
        <div className="board mt-3 d-flex justify-content-start align-items-start">
          {groups.map((group, index) => (
            <GroupCard key={index} id={group.id}>
              {tasksForGroup(group.id)}
            </GroupCard>
          ))}
        </div>
        <CreateGroupModal
          openModal={showCreateModal}
          onClose={handleCloseModal}
        />
      </MainLayout>
    </div>
  );
};

export default Board;
