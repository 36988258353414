import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { useDrag } from "react-dnd";
import TaskServices from "services/taskServices";
import { updateTasks } from "redux/actions/action";
import EditTaskModal from "pages/modals/EditTaskModal";

const TaskCard = (props) => {
  const { id } = props;
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const tasks = useSelector((state) => state.taskReducers.tasks);
  const taskData = tasks.find((item) => item.id === id);

  // Task moving function
  const changeTaskGroup = (currentTask, groupId) => {
    const taskServices = new TaskServices();

    const updatedTasks = tasks.map((item) => {
      return item.id === currentTask.id
        ? { ...item, groupDto: { ...item.groupDto, id: groupId } }
        : item;
    });

    dispatch(updateTasks(updatedTasks));

    taskServices.updateTask({
      ...taskData,
      id: currentTask.id,
      groupDto: {
        id: groupId,
      },
    });
  };

  // Delete task
  const handleRemove = () => {
    const taskServices = new TaskServices();

    const updatedTasks = tasks.filter((item) => item.id !== id);
    dispatch(updateTasks(updatedTasks));

    taskServices.deleteTask({
      ...taskData,
      id,
    });
  };

  const handleOpenEditModal = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const [{ isDragging }, drag] = useDrag({
    type: "tasks",
    item: { id, name: taskData.name },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (dropResult) {
        changeTaskGroup(item, dropResult.id);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  return (
    <div
      ref={drag}
      className="task-card my-3 rounded-1 text-dark"
      style={{ opacity }}
    >
      <div className="task-head d-flex justify-content-between p-2 rounded-top">
        <h6 className="mb-0">{taskData.name}</h6>
        <div className="buttons">
          <Button
            variant="primary"
            size="sm"
            className="mx-1"
            onClick={handleOpenEditModal}
          >
            Edit
          </Button>
          <span
            aria-hidden="true"
            className="close text-danger mx-1"
            onClick={handleRemove}
          >
            &times;
          </span>
        </div>
      </div>
      <div className="p-3">{taskData.body}</div>
      <EditTaskModal id={id} openModal={openModal} onClose={handleClose} />
    </div>
  );
};

export default TaskCard;
