import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import TaskServices from "services/taskServices";
import { updateTasks } from "redux/actions/action";
import MainModal from "component/modal/MainModal";

const EditTaskModal = (props) => {
  const { id, openModal, onClose } = props;
  const dispatch = useDispatch();

  const tasks = useSelector((state) => state.taskReducers.tasks);

  const taskData = useMemo(() => {
    return tasks.find((item) => item.id === id);
  }, [tasks, id]);

  const handleSave = (name, description) => {
    const taskServices = new TaskServices();

    let updatedTasks = tasks.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          name,
          body: description,
          groupDto: { ...item.groupDto },
        };
      }

      return item;
    });

    dispatch(updateTasks(updatedTasks));
    onClose();

    taskServices.updateTask({
      id: id,
      name,
      body: description,
      groupDto: taskData.groupDto,
    });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <MainModal
      taskId={id}
      title="Edit Task"
      show={openModal}
      onConfirm={handleSave}
      onClose={handleClose}
      confirmName="Save"
      cancelName="Cancel"
    />
  );
};

export default EditTaskModal;
